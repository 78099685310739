import { Button } from '../shadcn/ui/button'
import { Input } from "../shadcn/ui/input"
import { Label } from "../shadcn/ui/label"
import { AlertCircle } from 'lucide-react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn/ui/select"
import { Checkbox } from "../shadcn/ui/checkbox"

import React from 'react'
import CheckMarkAnimated from '../other_ui/CheckMarkAnimated'

function WaitingListForm({ handleSubmit,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    source,
    setSource,
    additionalSource,
    setAdditionalSource,
    isBetaTester,
    setIsBetaTester, 
    error,
    setError,
    success,
    setSuccess,
    privacyViewed,
    setPrivacyViewed,
    privacyViewedError,
    }) {

    const referralOptions = [
        'LinkedIn',
        'Instagram',
        'Google',
        'Contacted directly by Founder (Branko)',
        'Other (please specify below)'
    ]

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
        {!success ? (
        <>
        
            <div className="space-y-2">
                <Label htmlFor="firstName">First Name</Label>
                <Input
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                />
            </div>
            <div className="space-y-2">
                <Label htmlFor="lastName">Last Name</Label>
                <Input
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                />
            </div>
            <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                />
            </div>
            <div className="space-y-2">
                <Label htmlFor="source">Where did you hear about us?</Label>
                <Select value={source} onValueChange={setSource} required>
                <SelectTrigger id="source">
                    <SelectValue placeholder="Select an option" />
                </SelectTrigger>
                <SelectContent>
                    {referralOptions.map((element) =>  (
                        <SelectItem value={element}>{element}</SelectItem>
                    )
                    )}
                </SelectContent>
                </Select>
            </div>
            {source === 'Other (please specify below)' && (
                <div className="space-y-2">
                    <Label htmlFor="additionalSource">Please specify further where you heard about us:</Label>
                    <Input
                        id='additionalSource'
                        type='additionalSource'
                        value={additionalSource}
                        onChange={(e) => setAdditionalSource(e.target.value)}
                    />
                </div>
            )}
            <div className="flex items-center space-x-2">
                <Checkbox
                id="beta_tester"
                checked={isBetaTester}
                onCheckedChange={() => setIsBetaTester(!isBetaTester)}
                />
                <Label htmlFor="beta_tester">I'm also interested in being contacted about the possibility of participation in free beta testing.</Label>
            </div>
            <div className="flex items-center space-x-2">
                <Checkbox
                id="privacyViewed"
                checked={privacyViewed}
                onCheckedChange={() => setPrivacyViewed(!privacyViewed)}
                />
                <Label htmlFor="privacyViewed">
                    I have read the <a href='/privacy-policy' target="_blank" rel="noopener noreferrer" className='hover:underline font-bold'>privacy policy</a>.
                </Label>
            </div>
                {privacyViewedError && (
                    <p className="text-sm text-red-500 flex items-center gap-1">
                        <AlertCircle className="h-4 w-4" />
                        You must read the privacy policy.
                    </p>
                )}
        </>
            ) : (
                <CheckMarkAnimated />
            )
        }
        {error && (
            <p className="text-sm text-red-500 flex items-center gap-1">
                <AlertCircle className="h-4 w-4" />
                {error}
            </p>
        )}
        {!success &&
            <Button type="submit" className="w-full">Sign Up</Button>
        }
    </form>
    )
}

export default WaitingListForm