import React, { useState } from 'react'
import HeroSection from '../../components/home_page/HeroSection'
import PricingSection from '../../components/home_page/PricingSection'
import { Link } from 'react-router-dom'
import { Input } from '../../components/shadcn/ui/input'
import { Button } from '../../components/shadcn/ui/button'
import HomePageFeaturesSelect from '../../components/home_page/HomePageFeaturesSelect'
import HomePageQuestionDisplay from '../../components/home_page/HomePageQuestionDisplay'
import HomePageTextbookDisplay from '../../components/home_page/HomePageTextbookDisplay'
import HomePageAnalyticsDisplay from '../../components/home_page/HomePageAnalyticsDisplay'
import WhyUsAccordion from '../../components/home_page/WhyUsAccordion'
import HeaderMedium from '../../components/typography/HeaderLarge'

function HomePage() {
  const [selectedFeature, setSelectedFeature] = useState(1)
  const [userAnswer, setUserAnswer] = useState(null)
  const [isAnswered, setIsAnswered] = useState(false)
  
  // for sign-up form:
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [source, setSource] = useState('')
  const [additionalSource, setAdditionalSource] = useState('')
  const [isBetaTester, setIsBetaTester] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [privacyViewed, setPrivacyViewed] = useState(false)
  const [privacyViewedError, setPrivacyViewedError] = useState(false)

  function handleChangeSelectedFeature(feature) {
    if(selectedFeature === feature) return
    setSelectedFeature(feature)
  }

  return (
    <>
      <HeroSection 
        email={email}
        setEmail={setEmail}
        error={error}
        setError={setError}
        success={success}
        setSuccess={setSuccess}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        source={source}
        setSource={setSource}
        additionalSource={additionalSource}
        setAdditionalSource={setAdditionalSource}
        isBetaTester={isBetaTester}
        setIsBetaTester={setIsBetaTester}
        privacyViewed={privacyViewed}
        setPrivacyViewed={setPrivacyViewed}
        privacyViewedError={privacyViewedError}
        setPrivacyViewedError={setPrivacyViewedError}
      />
      <div className="text-center mb-8 text-4xl mt-4">
        <HeaderMedium
          text={`Our Features`}
          blackText={true}
          underline={true}
        />
      </div>
      <HomePageFeaturesSelect
        selectedFeature={selectedFeature}
        handleChangeSelectedFeature={handleChangeSelectedFeature}
      />
      {
        selectedFeature === 0 ? 
        <HomePageQuestionDisplay
          userAnswer={userAnswer}
          setUserAnswer={setUserAnswer}
          isAnswered={isAnswered}
          setIsAnswered={setIsAnswered}
        /> 
        :
        selectedFeature === 1 ?
        <HomePageAnalyticsDisplay />
        :
        selectedFeature === 2 ?
        <HomePageTextbookDisplay />
        : null
      }
      {/* <WhyUsAccordion /> */}
    </>
  )
}

export default HomePage