"use client"

import { motion } from "framer-motion"

export default function CheckMarkAnimated() {
  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i) => {
      const delay = 1 + i * 0.5
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
          opacity: { delay, duration: 0.01 }
        }
      }
    }
  }

  return (
    <div>
        <div className="flex items-center justify-center ">
          <motion.svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            initial="hidden"
            animate="visible"
          >
            <motion.circle
              cx="50"
              cy="50"
              r="45"
              stroke="#22c55e"
              strokeWidth="4"
              fill="none"
              variants={draw}
              custom={0}
            />
            <motion.path
              d="M 30 50 L 45 65 L 70 35"
              stroke="#22c55e"
              strokeWidth="4"
              fill="none"
              variants={draw}
              custom={1}
            />
          </motion.svg>
        </div>
        <p className="text-center text-sm text-green-500">
            Thank you for joining our waitlist! <br />
            We will send you an email when we are ready to go live.
        </p>
    </div>
  )
}