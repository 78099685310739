import React, {useState} from 'react'
import LogoutButton from './LogoutButton';
import { useSelector, useDispatch } from 'react-redux';
import { Separator } from '../shadcn/ui/separator';
import { Button } from '../shadcn/ui/button';
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
  } from "../shadcn/ui/sheet"

  import { Link, useNavigate } from 'react-router-dom';  // If you're using react-router for navigation
import { setUnauthenticated } from '../../redux/authActions';
import { useTheme } from './ThemeContext';
import { Moon, Sun, ChevronDown, ChevronUp, Home, BookOpen, LineChart, AtSign, Info, BookOpenCheck, SquareCheckBig } from 'lucide-react';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
    DropdownMenuSeparator,
    DropdownMenuLabel
  } from "../shadcn/ui/dropdown-menu"

import { useAuth0 } from "@auth0/auth0-react";
import LogoIcon from './LogoIcon';
import { animated } from 'react-spring';
import { useDropdownAnimation } from '../hooks/animation/useDropdownAnimation';


function Navbar() {    
    const { theme, toggleTheme } = useTheme();
    const [msraOpen, setMsraOpen] = useState(false);
    const msraAnimation = useDropdownAnimation(msraOpen);
    const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();

  
    const [mobileStudyOpen, setMobileStudyOpen] = useState(false);
    const mobileStudyAnimation = useDropdownAnimation(mobileStudyOpen);

    const [mobileMsraOpen, setMobileMsraOpen] = useState(false);
    const mobileMsraAnimation = useDropdownAnimation(mobileMsraOpen)
  
    const textColor = theme === 'dark' ? '#eeeeee' : '#183477';


    const navigate = useNavigate();

    const toggleItems = (exam) => {
        if (exam === 'MSRA') {
            setMsraOpen(!msraOpen)
        } else if (exam === 'mobile-study'){
            setMobileStudyOpen(!mobileStudyOpen)
        } else if (exam === 'mobile-msra'){
            setMobileMsraOpen(!mobileMsraOpen)
        }
      };

      const mobileStudyIcon = {
        transition: 'transform 0.3s ease',
        transform: mobileStudyOpen ? 'rotate(180deg)' : 'rotate(0deg)'
    };

      const mobileMSRAIcon = {
        transition: 'transform 0.3s ease',
        transform: mobileMsraOpen ? 'rotate(180deg)' : 'rotate(0deg)'
    };

      const MSRAIcon = {
        transition: 'transform 0.3s ease',
        transform: msraOpen ? 'rotate(180deg)' : 'rotate(0deg)'
    };

    return (
    <div className='navbar'>
        <nav className="w-full h-60px z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-800">
            <div className="max-w-screen-lg flex flex-wrap justify-between items-center mx-auto p-2">
                <Link to={isAuthenticated ? "/profile" : "/"} className="flex items-center space-x-3 ml-0 rtl:space-x-reverse">
                    <div className="hidden md:block">
                        <LogoIcon
                            width={50}
                            height={55}
                        />
                    </div>
                    <div className="block md:hidden ml-0">
                        <LogoIcon
                            width={40}
                            height={45}
                        />
                    </div>
                    <span className="scroll-m-20 hidden md:block text-2xl pt-2 font-semibold tracking-tight" id="navbar-title" style={{ color: textColor }}>
                        ExamVitals
                    </span>
                </Link>
                <Button onClick={() => toggleTheme()} variant="outline" size="icon">
                    <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:rotate-90 dark:scale-0" />
                    <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
                    <span className="sr-only">Toggle theme</span>
                </Button>
            </div>
        </nav>
    </div>

  )
}

export default Navbar