import React from 'react'
import { Button } from '../shadcn/ui/button'
import { useNavigate } from 'react-router-dom'
import { Separator } from '@radix-ui/react-dropdown-menu'

function Footer() {
    const navigate = useNavigate()
  return (
    <footer className="text-black dark:text-white py-4 border-t border-gray-200 dark:border-gray-800 w-full">
        <div className="container mx-auto flex flex-col items-center justify-between px-4 md:px-6">
          <nav className="flex flex-col md:flex-row items-center sm:gap-4">
              <Button onClick={() => navigate('/privacy-policy')} className="text-black dark:text-white text-xs" variant='link'>
              Privacy Policy
              </Button>
              {/* <Button onClick={() => navigate('/terms-and-conditions')} className="text-black dark:text-white text-xs" variant='link'>
              Terms &amp; Conditions
              </Button> */}
              <Button className="text-black dark:text-white text-xs"  variant='link'>
                <a href="mailto:contact@exam-vitals.com">Contact Us</a>
              </Button>
          </nav>
          <div className="text-xs my-2">
            {/* &copy; 2024  */}
            Exam Vitals Limited</div>
        </div>
    </footer>
  )
}

export default Footer