import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from './components/shadcn/ui/toaster';
import { ThemeProvider } from './components/common/ThemeContext';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';


// OLD
import Textbook from './pages/MSRA/old/Textbook';


// UNAUTHEDPAGES
import HomePage from './pages/public/HomePage';
import About from './pages/public/About'
import Pricing from './pages/public/Pricing';
import ContactUs from './pages/public/ContactUs';
import Privacy from './pages/public/Privacy';
import TermsAndConditions from './pages/public/TermsAndConditions';

// COMPONENTS
import Navbar from './components/common/Navbar';
import ProtectedRoute from './components/Middleware/ProtectedRoute'
import Footer from './components/common/Footer';
import MSRAProtectedRoute from './components/Middleware/MSRAProtectedRoute';

// AUTHED PAGES
import Profile from './pages/MSRA/new/all_auth/Profile';
import MSRAHomePage from './pages/MSRA/new/MSRA/MSRAHomePage';
import NoMSRASubscription from './pages/errors/NoMSRASubscription';
import CPSHomePage from './pages/MSRA/new/MSRA/CPS/CPSHomePage';
import PDHomePage from './pages/MSRA/new/MSRA/PD/PDHomePage';
import CPSActiveSession from './pages/MSRA/new/MSRA/CPS/CPSActiveSession';
import PDActiveSession from './pages/MSRA/new/MSRA/PD/PDActiveSession';
import CPSSessionSummary from './pages/MSRA/new/MSRA/CPS/CPSSessionSummary';
import PDSessionSummary from './pages/MSRA/new/MSRA/PD/PDSessionSummary';
import PDAllSessionSummary from './pages/MSRA/new/MSRA/PD/PDAllSessionSummary';
import CPSAllSessionSummary from './pages/MSRA/new/MSRA/CPS/CPSAllSessionSummary';
import CPSSingleSessionReview from './pages/MSRA/new/MSRA/CPS/CPSSingleSessionReview';
import PDSingleSessionReview from './pages/MSRA/new/MSRA/PD/PDSingleSessionReview';
import MSRADashboard from './pages/MSRA/new/MSRA/MSRADashboard';
import MSRATextbook from './pages/MSRA/new/MSRA/MSRATextbook';


//pages & components

const App = () => {

  // useAuthCheck()

  return ( 
    <ThemeProvider>
      <div className="App flex flex-col min-h-screen">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <Navbar />
              <main className="flex flex-col flex-grow">
                <div className="pages flex-grow">
                  <Routes>
                    {/* NEW ROUTES */}
                    
                  {/* DONE */}
                    <Route
                      path='/'
                      element={<HomePage />}
                      />
                  {/* DONE */}
                    {/* <Route
                      path='/pricing/:examId'
                      element={<Pricing />}
                      /> */}
                  {/* NOT DONE */}
                    {/* <Route
                      path='/contact-us'
                      element={<ContactUs />}
                      /> */}
                  {/* NOT DONE */}
                    <Route
                      path='/privacy-policy'
                      element={<Privacy />}
                      />
                  {/* NOT DONE */}
                    {/* <Route
                      path='/terms-and-conditions'
                      element={<TermsAndConditions />}
                      /> */}
                  {/* NOT DONE */}
                    {/* <Route
                      path='/about'
                      element={<About />}
                      /> */}
                  {/* DONE */}
                    {/* <Route
                      path="/profile"
                      element={
                        <ProtectedRoute>
                          <Profile />
                        </ProtectedRoute>
                      }
                      /> */}
                  {/* DONE */}
                    {/* <Route 
                      path="/no-access/MSRA"
                      element={
                        <ProtectedRoute>
                          <NoMSRASubscription />
                        </ProtectedRoute>
                      }
                    /> */}
                  {/* DONE*/}
                    {/* <Route 
                      path="/MSRA/home"
                      element={
                        <MSRAProtectedRoute>
                          <MSRAHomePage />
                        </MSRAProtectedRoute>
                      }
                      /> */}
                  {/* DONE */}
                    {/* <Route
                      path="/MSRA/CPS/start-session"
                      element={
                        <MSRAProtectedRoute>
                          <CPSHomePage />                          
                        </MSRAProtectedRoute>
                      }
                      /> */}
                  {/* DONE */}
                    {/* <Route
                      path="/MSRA/PD/start-session"
                      element={
                        <MSRAProtectedRoute>
                          <PDHomePage />
                        </MSRAProtectedRoute>
                      }
                      /> */}
                  {/* DONE */}
                    {/* <Route 
                      path="/MSRA/CPS/session"
                      element={
                        <MSRAProtectedRoute>
                          <CPSActiveSession />
                        </MSRAProtectedRoute>
                      }
                      /> */}
                  {/* DONE */}
                    {/* <Route 
                      path="/MSRA/PD/session"
                      element={
                        <MSRAProtectedRoute>
                          <PDActiveSession />
                        </MSRAProtectedRoute>
                      }
                      /> */}
                  {/* DONE */}
                    {/* <Route
                      path="/MSRA/session-summary/CPS/:sessionId"
                      element={
                        <MSRAProtectedRoute>
                          <CPSSessionSummary />
                        </MSRAProtectedRoute>
                      }
                      /> */}
                  {/* DONE */}
                    {/* <Route
                      path="/MSRA/session-summary/PD/:sessionId"
                      element={
                        <MSRAProtectedRoute>
                          <PDSessionSummary />
                        </MSRAProtectedRoute>
                      }
                      /> */}
                  {/* DONE */}
                    {/* <Route
                      path="/MSRA/all-session-summary/CPS"
                      element={
                        <MSRAProtectedRoute>
                          <CPSAllSessionSummary />
                        </MSRAProtectedRoute>
                      }
                      /> */}
                  {/* DONE */}
                    {/* <Route
                      path="/MSRA/all-session-summary/PD"
                      element={
                        <MSRAProtectedRoute>
                          <PDAllSessionSummary />
                        </MSRAProtectedRoute>
                      }
                      /> */}
                  {/* DONE */}
                    {/* <Route 
                      path="/MSRA/session-review/CPS/:id" 
                      element={
                        <MSRAProtectedRoute>
                          <CPSSingleSessionReview />
                        </MSRAProtectedRoute>
                      } /> */}
                  {/* DONE */}
                    {/* <Route 
                      path="/MSRA/session-review/PD/:id" 
                      element={
                        <MSRAProtectedRoute>
                          <PDSingleSessionReview />
                        </MSRAProtectedRoute>
                      } /> */}

                  {/* DONE */}
                    {/* <Route
                      path="/MSRA/dashboard"
                      element={
                        <MSRAProtectedRoute>
                          <MSRADashboard />
                        </MSRAProtectedRoute>
                      }
                      /> */}
                  {/* NOT DONE */}
                    {/* <Route
                      path="/MSRA/textbook/:id"
                      element={
                        <MSRAProtectedRoute>
                          <MSRATextbook />
                        </MSRAProtectedRoute>
                      }
                      /> */}
                  </Routes>
                </div>
                <Footer />
              </main>
            </BrowserRouter>
          </PersistGate>
        </Provider>
        <ToastContainer />
        <Toaster />
      </div>
    </ThemeProvider>
   );
}
 
export default App;