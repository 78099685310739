import { Button } from "../shadcn/ui/button";
import { Input } from "../shadcn/ui/input";
import { AlertCircle, ArrowRight } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../shadcn/ui/dialog"
import WaitingListForm from "./WaitingListForm";

const HeroSection = ({privacyViewed, setPrivacyViewed, privacyViewedError, setPrivacyViewedError, email, setEmail, error, setError, success, setSuccess, firstName, setFirstName, lastName, setLastName, source, setSource, additionalSource, setAdditionalSource, isBetaTester, setIsBetaTester}) => {

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);
  
    if (!privacyViewed) {
      setPrivacyViewedError(true);
      return;
    } else {
      setPrivacyViewedError(false);
    }
  
    // Basic email validation
    if (!email) {
      setError("Please enter your email address.");
      return;
    }
  
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }
  
    // Function to get the CSRF token from cookies
    const getCookie = (name) => {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Check if the cookie starts with the name we are looking for
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    };
  
    // Get the CSRF token from the cookie
    const csrfToken = getCookie('csrftoken');
  
    // Send email to backend
    try {
      const response = await fetch("https://exam-vitals.com/custom_api/users/join_waiting_list/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,  // Add CSRF token to headers
        },
        body: JSON.stringify({
          email,
          first_name: firstName,
          last_name: lastName,
          referral_method: source,
          additional_referral_information: additionalSource,
          is_beta_tester: isBetaTester
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setSuccess(true);
        setEmail("");
        setFirstName("");
        setLastName("");
        setSource("");
        setAdditionalSource("");
        setIsBetaTester(false);
      } else {
        setError(data.error || "Something went wrong. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    }
  };
  
  return (
    <section className="w-full pt-6 md:pt-16 lg:pt-20 xl:pt-24 pb-4">
        <div className="container px-4 md:px-6">
          <div className="grid gap-6 items-center">
            <div className="flex flex-col justify-center space-y-8 text-center">
              <div className="mb-2">
                <h1 className="text-3xl mb-2 sm:mb-6 font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">
                  Multi-Specialty Recruitment Assessment (MSRA)
                  <br />
                  Question Bank
                </h1>
                <p className="max-w-1/2 text-muted-foreground md:text-xl mx-auto">
                    Increase your chances of specialty training placement with our evidence-based MSRA question bank.
                    <br /> 
                    Created by a doctor, for doctors.
                </p>
              </div>
              <div className="w-full text-sm max-w-sm space-y-2 mx-auto">
                <div className="w-full max-w-sm space-y-2">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button variant="cta" size="lg">
                        Join the Waiting List
                      </Button>
                    </DialogTrigger>
                    <DialogContent>
                      {!success && 
                      <DialogHeader>
                        <DialogTitle>Join our Waiting List</DialogTitle>
                        <DialogDescription>
                          Sign up to be notified when we launch and get early access to our product.  
                        </DialogDescription>
                      </DialogHeader>
                      }
                      <WaitingListForm 
                        handleSubmit={handleSubmit} 
                        email={email}
                        setEmail={setEmail}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        source={source}
                        setSource={setSource}
                        additionalSource={additionalSource}
                        setAdditionalSource={setAdditionalSource}
                        isBetaTester={isBetaTester}
                        setIsBetaTester={setIsBetaTester}
                        error={error}
                        setError={setError}
                        success={success}
                        setSuccess={setSuccess}
                        privacyViewed={privacyViewed}
                        setPrivacyViewed={setPrivacyViewed}
                        privacyViewedError={privacyViewedError}
                        setPrivacyViewedError={setPrivacyViewedError}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default HeroSection;
