import React from 'react'

function SpinLoader() {
    return (
        <div className="flex items-center justify-center min-h-[75vh]">
            <div className="animate-spin rounded-full border-4 border-primary border-t-transparent h-16 w-16" />
        </div>
      )
}

export default SpinLoader